(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('NotificacaoMySuffixDetailController', NotificacaoMySuffixDetailController);

    NotificacaoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Notificacao', 'Documento', 'Pessoa'];

    function NotificacaoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Notificacao, Documento, Pessoa) {
        var vm = this;

        vm.notificacao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:notificacaoUpdate', function(event, result) {
            vm.notificacao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
