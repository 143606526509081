(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('AnexoMySuffixDetailController', AnexoMySuffixDetailController);

    AnexoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Anexo', 'Documento', 'TipoAnexo'];

    function AnexoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Anexo, Documento, TipoAnexo) {
        var vm = this;

        vm.anexo = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var unsubscribe = $rootScope.$on('siacapApp:anexoUpdate', function(event, result) {
            vm.anexo = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
