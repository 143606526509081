(function() {
    'use strict';

    var app =  angular.module('siacapApp');
    
    var file;
    var i;
    
    var crt = app.controller('PessoaJurMySuffixController', PessoaJurMySuffixController);

    PessoaJurMySuffixController.$inject = ['$scope','$state', 'PessoaJur', 'PessoaJurSearch', 'PessoaFisica', 'PessoaFisicaSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function PessoaJurMySuffixController($scope, $state, PessoaJur, PessoaJurSearch, PessoaFisica, PessoaFisicaSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;
        
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.importar = false;
        vm.mudar = mudar;
        vm.batch = batch;
        vm.trocar = trocar;
        
        vm.importInfo = {
        		success: 0,
        		error: 0
        };
        
        vm.showInfo = false;
        
        vm.gridOptions = {};
        
        vm.erros = [];
        
        vm.reset = reset;
        
        function reset() {
            vm.gridOptions.data = [];
            vm.gridOptions.columnDefs = [];
          }

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                PessoaJurSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                PessoaJur.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.pessoaJurs = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        function mudar(){
        	if (vm.importar == true) {
        		vm.importar = false;
			}else {
				vm.importar = true;
				vm.importInfo.success = 0;
	            vm.importInfo.error = 0;
				reset();
				
				if (vm.showInfo) {
					trocar();
				}
				
			}
        	
        }
        
        function trocar(){
        	vm.showInfo = !vm.showInfo;
        }
        
        function batch(){
        	if(vm.gridOptions.data.length == 0){
        		alert("Favor, selecionar um arquivo de importação");
        	}else {
        		
        		//verificar se a tabela está no padrão de importação
        		
        		vm.importInfo.success = 0,
        		vm.importInfo.error = 0;
        		vm.erros = [];
        		i = 2; // variável incrementa a partir do numero da primeira linha do excel
        		
        		file.forEach(function(item,index){
        			
        			if (item.CPF && !item.CNPJ) {
        				var pessoa = toPessoaFisica(item);
        				saveFisica(pessoa);
        			} else if (item.CNPJ && !item.CPF) {
        				var pessoa = toPessoaJur(item);
        				saveJur(pessoa);
        			}
        			
        			
        		})
        		
        		trocar();
        	}
        	
        	
        }
        
        function toPessoaFisica(i) {
        	
        	var pf = new PessoaFisica({
        		cpf: i.CPF,
        		nome:i.NOME,
    			logradouro: i.LOGRADOURO_RESIDENCIAL,
    			numero: i.NUMERO,
    			complemento: i.COMPLEMENTO,
    			bairro: i.BAIRRO,
    			cidade: i.MUNICIPIO,
    			uf: i.UF,
    			cep: i.CEP,	
    			});
        	
        	return pf;
        }
        
        function toPessoaJur(i) {
	
        	var pj = new PessoaJur({
        		cnpj: i.CNPJ,
        		nome:i.NOME,
    			logradouro: i.LOGRADOURO_RESIDENCIAL,
    			numero: i.NUMERO,
    			complemento: i.COMPLEMENTO,
    			bairro: i.BAIRRO,
    			cidade: i.MUNICIPIO,
    			uf: i.UF,
    			cep: i.CEP,
    				
    			/* incricaoEstadual: i.incricao_estadual.replace("-", ""),
    			incricaoMunicipal: i.incricao_municipal.replace("-", ""),
    			tipo: i.natu_juridica,
    			fantasia: i.nome_fantasia,
    			cnae: {id:i.cnae},
    			segmento: {id:i.segmento},
    			atividade: {id:i.atividade},*/ 
    			
    			});
        	
        	return pj;
        }
        
        function saveJur(d){
        	vm.isSaving = true;
        	
        	PessoaJur.save(d, onSaveSuccess, onSaveError);
        	
        }
        
        function saveFisica(d){
        	vm.isSaving = true;
        	
        	PessoaFisica.save(d, onSaveSuccess, onSaveError);
        	
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:pessoaJurUpdate', result);
            
            vm.importInfo.success++;
            vm.pessoaJurs = PessoaJur.query();
            
            vm.isSaving = false;
            i++;
        }

        function onSaveError () {
        	vm.importInfo.error++;
        	vm.erros[vm.erros.length] = i
            vm.isSaving = false;
        	i++;
        }
        
    }
    
    
    crt.directive("filereadpj", [function () {
  	  return {
  		    scope: {
  		      opts: '='
  		    },
  		    link: function ($scope, $elm, $attrs) {
  		      $elm.on('change', function (changeEvent) {
  		        var reader = new FileReader();
  		        
  		        reader.onload = function (evt) {
  		          $scope.$apply(function () {
  		            var data = evt.target.result;
  		            
  		            var workbook = XLSX.read(data, {type: 'binary'});
  		            
  		            var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
  		            
  		            var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
  		            
  		            $scope.opts.columnDefs = [];
  		            headerNames.forEach(function (h) {
  		              $scope.opts.columnDefs.push({ field: h });
  		            });
  		            
  		            $scope.opts.data = data;
  		            file = data;
  		            
  		            $elm.val(null);
  		          });
  		        };
  		        
  		        reader.readAsBinaryString(changeEvent.target.files[0]);
  		      });
  		    }
  		  }
  }]);
})();
