(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('OrgaoMySuffixDialogController', OrgaoMySuffixDialogController);

    OrgaoMySuffixDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Orgao'];

    function OrgaoMySuffixDialogController ($timeout, $scope, $stateParams, $uibModalInstance, entity, Orgao) {
        var vm = this;
        vm.orgao = entity;
        vm.clear = clear;
        vm.save = save;
        vm.ufs = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.orgao.id !== null) {
                Orgao.update(vm.orgao, onSaveSuccess, onSaveError);
            } else {
                Orgao.save(vm.orgao, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:orgaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


    }
})();
