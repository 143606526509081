(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('AutoInfracaoMySuffixDialogController', AutoInfracaoMySuffixDialogController);

    AutoInfracaoMySuffixDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'AutoInfracao', 'Situacao', 'Documento', 'Pessoa', 'PessoaRelacao'];

    function AutoInfracaoMySuffixDialogController ($http, $timeout, $scope, $stateParams, $uibModalInstance, entity, AutoInfracao, Situacao, Documento, Pessoa, PessoaRelacao) {
        var vm = this;

        vm.autoInfracao = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //vm.situacoes = Situacao.query();
        
        $http.get('/api/situacoes/todas').then(function(response) {
        	vm.situacoes = response.data;
        });
        
        $http.get('/api/documentos/todos').then(function(response) {
        	vm.documentos = response.data;
        });
        
        //componente de relacionamentos
        vm.adicionar = adicionar;
        //vm.pessoas = Pessoa.query();
        $http.get('/api/pessoas/todas').then(function(response) {
        	vm.pessoas = response.data;
        });
        vm.pessoa;
        vm.juridicaPessoa;
        vm.pessoaRelacao = PessoaRelacao.query(); 
        vm.temp2 = [];
        vm.temp2 = vm.autoInfracao.juridicaPessoa;
       
        
        
        if( vm.autoInfracao.juridicaPessoa == null){
        	vm.temp2 = [];
      }

       //fim componente de relacionamentos
        
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
            vm.autoInfracao.juridicaPessoa = vm.temp2;
            
            if (vm.autoInfracao.id !== null) {
                AutoInfracao.update(vm.autoInfracao, onSaveSuccess, onSaveError);
            } else {
                AutoInfracao.save(vm.autoInfracao, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:autoInfracaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataIni = false;
        vm.datePickerOpenStatus.dataFinal = false;
        vm.datePickerOpenStatus.dataSituacao = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
        function teste (){
        	alert("errado");
        }
        
        
        // componente relacionamentos
        function adicionar () {
        	
        	if(vm.pessoa != null ){
        		 
        		 var juridicaPessoaId =  {
       			      "pessoa": vm.pessoa
       			     
       			    }
        		 
        		 vm.juridicaPessoa =  {
         			      "pk": juridicaPessoaId,
         			      "pessoaRelacao": vm.pessoaJur.juridicaPessoa.pessoaRelacao.id,
         			      "createdBy": vm.pessoaJur.juridicaPessoa.pessoaRelacao.nome,
         			      
         			      "investigacao": '',
         			      "denunciado": false
         			      
       			 }
        		 
             	vm.temp2.push(vm.juridicaPessoa);        		
        	}else{
             	alert('Digite o nome ou CPF/CNPJ da Pessoa no campo indicado !');
            }
            
        }
           
      // fim de componente relacionamentos
        
        
        
    }
})();
