(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('PessoaRelacaoMySuffixDetailController', PessoaRelacaoMySuffixDetailController);

    PessoaRelacaoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PessoaRelacao', 'Pessoa'];

    function PessoaRelacaoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, PessoaRelacao, Pessoa) {
        var vm = this;

        vm.pessoaRelacao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:pessoaRelacaoUpdate', function(event, result) {
            vm.pessoaRelacao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
