(function() {
    'use strict';

    var app =  angular
    .module('siacapApp');
    
    var file;
    var i;
    
    var crt =  app.controller('DocumentoMySuffixController', DocumentoMySuffixController);
        
   
    DocumentoMySuffixController.$inject = ['$scope', '$state', 'Documento', 'DocumentoSearch', 'TipoDocumento', 'AutoInfracao', 'Segmento', 'Situacao', 'Cnae', 'DateUtils','TipoDocumentoSearch', 'PessoaJur', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    
    
    
    function DocumentoMySuffixController($scope, $state, Documento, DocumentoSearch, TipoDocumento, AutoInfracao, Segmento, Situacao, Cnae,  DateUtils, TipoDocumentoSearch, PessoaJur, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.mudar = mudar;
        vm.importar = false;
        vm.batch = batch;
        vm.generateDocx = generateDocx;
        
        vm.importInfo = {
        		success: 0,
        		error: 0
        };
        
        vm.showInfo = false;
        
        vm.gridOptions = {};
        
        vm.erros = [];
        
        
        
        vm.reset = reset;
        
        function reset() {
          vm.gridOptions.data = [];
          vm.gridOptions.columnDefs = [];
        }
        

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                DocumentoSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Documento.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.documentos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }
        
        
        function loadFile(url,callback){
            JSZipUtils.getBinaryContent(url,callback);
        }
        
        function generateDocx() {
            loadFile("content/examples/tag-example.docx",function(error,content){
                if (error) { throw error };
                var zip = new JSZip(content);
                var data = new Date();
                var doc=new Docxtemplater().loadZip(zip)
                doc.setData({
                    first_name: 'Manoel',
                    last_name: 'Heleno',
                    phone: '0652455478',
                    description: 'Prova de Conceito',
                    numero: '0001/2018',
                    date: data.getDay() + ', de ' + data.getMonth().toString() + ' de ' + data.getYear()
                });

                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render()
                }
                catch (error) {
                    var e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    }
                    console.log(JSON.stringify({error: e}));
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
                    throw error;
                }

                var out=doc.getZip().generate({
                    type:"blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }) //Output the document using Data-URI
                saveAs(out,"output.docx")
            });        	
        } 
                
        function mudar(){
        	if (vm.importar == true) {
        		vm.importar = false;
			}else {
				vm.importar = true;
				vm.importInfo.success = 0;
	            vm.importInfo.error = 0;
				reset();
				
				if (vm.showInfo) {
					trocar();
				}
				
			}
        	
        }
        
        function trocar(){
        	vm.showInfo = !vm.showInfo;
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        
        
        function batch(){
        	if(vm.gridOptions.data.length == 0){
        		alert("Favor, selecionar um arquivo de importação");
        	}else {
        		
        		//verificar se a tabela está no padrão de importação
        		
        		vm.importInfo.success = 0,
        		vm.importInfo.error = 0;
        		vm.erros = [];
        		i = 2; // variável incrementa a partir do numero da primeira linha do excel
        		
        		file.forEach(function(item,index){
        			var doc = toDocument(item);
        			save(doc);
        		})
        		
        		trocar();
        	}
        	
        	
        }
        
        function convertDateServer(date) {
            if (date) {
                var dateString = date.split('/');
                return new Date(dateString[2], dateString[1] - 1, dateString[0]);
            }
            return null;
        }

        
        function toDocument(i) {  
        	
        	// Transforma linha do excel em um objeto Documento
        	
        	var doc = new Documento({
        		numeroOrigem: i.COFIMP,
				comarca: {nome:i.COMARCA},
				numeroOficioEntrada: i.N_OFICIO_SEFAZ,
				dataOficioEntrada: convertDateServer(i.DT_OFICIO_SEFAZ),
				pessoaJur: {nome:i.RAZAO_SOCIAL, cnpj:i.CNPJ , incricaoEstadual: i.INSC_ESTADUAL,
							logradouro: i.LOGRADOURO, numero: i.NUMERO, complemento: i.COMPLEMENTO,
							bairro: i.BAIRRO, uf: i.UF, cep: i.CEP,
							}
				});
        	
        	doc.pessoaJur.cnae = {id:i.CNAE_FISCAL} 
        	doc.pessoaJur.segmento = {id:i.SEGMENTO} 
        	
        	doc.autos = Array(1);
        	
        	doc.autos[0] = {numero:i.PAT_NUMERO_AUTO,
        					situacao: {id: i.SIT_AUTO}, 
        					dataIni: convertDateServer('01/' +  i.PERIODO_FISCAL_INICIAL),
        					dataFinal: convertDateServer('01/' +  i.PERIODO_FISCAL_FINAL)
        					};
        	
        	doc.autos[0].valor = parseFloat(i.VALOR_TRIBUTO.replace(',',''));
        	doc.autos[0].multa = parseFloat(i.MULTA.replace(',',''));
        	doc.autos[0].juros = parseFloat(i.JUROS.replace(',',''));
        	
        	//TODO: Ajeitar datas
        	
        	return doc;
        }
        
        function save(d){
        	vm.isSaving = true;
        	
        	Documento.save(d, onSaveSuccess, onSaveError);
        	
        }
        
        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:pessoaJurUpdate', result);
            
            vm.importInfo.success++;
            vm.documentos = Documento.query();
            
            vm.isSaving = false;
            i++;
        }

        function onSaveError () {
        	vm.importInfo.error++;
        	vm.erros[vm.erros.length] = i
            vm.isSaving = false;
        	i++;
        }
        
    }
    
    
    crt.directive("fileread", [function () {
    	  return {
    		    scope: {
    		      opts: '='
    		    },
    		    link: function ($scope, $elm, $attrs) {
    		      $elm.on('change', function (changeEvent) {
    		        var reader = new FileReader();
    		        
    		        reader.onload = function (evt) {
    		          $scope.$apply(function () {
    		            var data = evt.target.result;
    		            
    		            var workbook = XLSX.read(data, {type: 'binary'});
    		            
    		            var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
    		            
    		            var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
    		            
    		            $scope.opts.columnDefs = [];
    		            headerNames.forEach(function (h) {
    		              $scope.opts.columnDefs.push({ field: h });
    		            });
    		            
    		            $scope.opts.data = data;
    		            file = data;
    		            
    		            $elm.val(null);
    		          });
    		        };
    		        
    		        reader.readAsBinaryString(changeEvent.target.files[0]);
    		      });
    		    }
    		  }
    }]);

})();
