(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('OficioMySuffixDialogController', OficioMySuffixDialogController);

    OficioMySuffixDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', '$q', 'entity', 'Oficio', 'Orgao', 'Documento', 'AutoInfracao'];

    function OficioMySuffixDialogController ($http, $timeout, $scope, $stateParams, $uibModalInstance, $q, entity, Oficio, Orgao, Documento, AutoInfracao) {
        var vm = this;

        vm.oficio = entity;
        vm.oficio.ano = new Date().getFullYear();
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //vm.orgaos = Orgao.query();
        //vm.documentos = Documento.query();
        $http.get('/api/documentos/todos').then(function(response) {
        	vm.documentos = response.data;
        });
        
        vm.orgaos = Orgao.query({filter: 'oficio-is-null'});
        
        $q.all([vm.oficio.$promise, vm.orgaos.$promise]).then(function() {
            if (!vm.oficio.executor || !vm.oficio.executor.id) {
                return $q.reject();
            }
            return Orgao.get({id : vm.oficio.executor.id}).$promise;
        }).then(function(orgao) {
            vm.orgaos.push(orgao);
        });
        
        vm.orgaos = Orgao.query();
        
        $http.get('/api/orgaos/todos').then( function(response) {
          vm.orgaosElem = response.data;
        });        
        
        var auto_id ;
        var autoInfracao;
        
        if (vm.oficio.id == null) {
        	Documento.get({id: vm.oficio.documento},function(doc){
        		//on success callback function
        		vm.oficio.documento = doc;
        		auto_id = vm.oficio.documento.autos[0].id;
            	AutoInfracao.get({id: auto_id},function(auto){
            		//on success callback function
            		autoInfracao = auto;
            	});
        	});
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.oficio.id !== null) {
                Oficio.update(vm.oficio, onSaveSuccess, onSaveError);
            } else {
            	if(typeof autoInfracao == "undefined") {
            		alert("Não é possível gerar Ofício sem Auto de Infração cadastrado");
            		
            	}
            	
//            	else if(!isNullOrUndef(vm.oficio.documento.localizacao)) {
//            		alert("Não é possível gerar Ofício Documento deve ter uma Localização!");
//            	}
            	
            	else {
            		geraroficio();
            	}
                
            }
        }
        
        function loadFile(url,callback){
            JSZipUtils.getBinaryContent(url,callback);
        }
        
        function geraroficio(){
        	//alert(autoInfracao);
        	if (vm.oficio.documento.tipo != null) {
	        	if (vm.oficio.documento.tipo.id == 1) {
	        		try {
	        		generateDocx2("content/images/examples/oficio-cofimp.docx"); 
	        		var now = new Date;
	        		vm.oficio.data = now;
	        		Oficio.save(vm.oficio, onSaveSuccess, onSaveError);
	        		}catch (error){
	        			alert("problema");
	        		}
	        		
				} else if(vm.oficio.documento.tipo.id == 11) {
					try {
						generateDocx2("content/images/examples/oficio-cofimmp.docx");
						var now = new Date;
						vm.oficio.data = now;
		        		Oficio.save(vm.oficio, onSaveSuccess, onSaveError);
						
					} catch (error) {
						alert("Problema");
					}
					
				}else if(vm.oficio.documento.tipo.id == 2){
					try {
						generateDocx2("content/images/examples/oficio-pa.docx");
						var now = new Date;
						vm.oficio.data = now;
		        		Oficio.save(vm.oficio, onSaveSuccess, onSaveError);
					} catch (error) {
						alert("Problema");
					}
					
				
				}
	        	
	        }else {
				alert("Documento sem Tipo, favor ajustar um Tipo Documento para o sistema gerar um Ofício");
				clear();
			}
        	
        }
        
        function isNullOrUndef(obj) {
      	  return obj == null;
     }
     
     function generateDocx2(file) {
         
 		loadFile(file,function(error,content){
         if (error) { throw error };
         var zip = new JSZip(content);
         var dataAtual = new Date();
         var monName = new Array ("janeiro", "fevereiro", "março", "abril", "maio", "junho", "agosto", "outubro", "novembro", "dezembro");
         var stringDate = dataAtual.getDate() + ' de ' + monName[dataAtual.getMonth()] + ' de ' + dataAtual.getFullYear();
         var docx = new Docxtemplater().loadZip(zip)
         
         
        	 docx.setData({
            	   
                 id:   ( !isNullOrUndef(vm.oficio.documento.id) ? vm.oficio.documento.id : "***") , 
                 arquimedes:   ( !isNullOrUndef(vm.oficio.documento.arquimedes) ? vm.oficio.documento.arquimedes : "***") , 
                 phone: '',
                 description: 'Ofício',
                 numero:   ( !isNullOrUndef(vm.oficio.numero) ? vm.oficio.numero : "***") , 
                 ano:  ( !isNullOrUndef(vm.oficio.ano) ? vm.oficio.ano : "***") , 
                 promotor: ( !isNullOrUndef(vm.oficio.promotor) ? vm.oficio.promotor : "***") , 
                 cargo: ( !isNullOrUndef(vm.oficio.cargo) ? vm.oficio.cargo : "***") , 
                 remetente: ( !isNullOrUndef(vm.oficio.documento.remetente) ? vm.oficio.documento.remetente.nome : "***") ,
                 date: stringDate,
                 orgao: ( !isNullOrUndef(vm.oficio.executor) ? vm.oficio.executor.nome : "***"),
                 endereco: ( !isNullOrUndef(vm.oficio.executor.logradouro) ? vm.oficio.executor.logradouro : "***") 
                 + ', ' + ( !isNullOrUndef(vm.oficio.executor.numero) ? vm.oficio.executor.numero : "***") 
                 + ', ' + ( !isNullOrUndef(vm.oficio.executor.bairro) ? vm.oficio.executor.bairro : "***")   
                 + ', ' + ( !isNullOrUndef(vm.oficio.executor.cidade) ? vm.oficio.executor.cidade : "***")
                 + '/' +( !isNullOrUndef(vm.oficio.executor.uf) ? vm.oficio.executor.uf : "***")
                 + ', CEP: ' 
                 + ( !isNullOrUndef(vm.oficio.executor.cep) ? vm.oficio.executor.cep : "***") + '.',
                 empresa: ( !isNullOrUndef(vm.oficio.documento.pessoaJur.nome) ? vm.oficio.documento.pessoaJur.nome : "***") ,
                 auto: ( !isNullOrUndef(vm.oficio.documento.autos[0].numero) ? vm.oficio.documento.autos[0].numero : "***") 
             });
                  

         try {
             docx.render()
         }
         catch (error) {
             var e = {
                 message: error.message,
                 name: error.name,
                 stack: error.stack,
                 properties: error.properties,
             }
             console.log(JSON.stringify({error: e}));
             // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
             throw error;
         }

         var out = docx.getZip().generate({
             type:"blob",
             mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
         }) //Output the document using Data-URI
         saveAs(out,"OFÍCIO - " + vm.oficio.numero + "_" + vm.oficio.ano +".docx")
 		});        	

     }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:oficioUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.data = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
