(function() {
    'use strict';

   var app =  angular
        .module('siacapApp');
        
        app.controller('ModalInstanceCtrl', ModalInstanceCtrl);
        app.controller('ModalInstanceAnexoCtrl', ModalInstanceAnexoCtrl);
        
        var crt = app.controller('DocumentoMySuffixDialogController', DocumentoMySuffixDialogController);
        
        
        ModalInstanceCtrl.$inject = ['$http','$uibModalInstance', 'items', '$timeout', '$scope', '$stateParams', 'entity',  'AutoInfracao', 'Situacao', 'Pessoa', 'PessoaRelacao'];
        
        function ModalInstanceCtrl ( $http, $uibModalInstance, items, $timeout, $scope, $stateParams, entity , AutoInfracao, Situacao, Pessoa, PessoaRelacao) {
      	  
        	 var vm = this;
        	 vm.autoInfracao = entity;
        	 vm.situacoes = Situacao.query();	// TO-DO: aqui precisa corrigir para retornar TODAS AS SITUAÇÕES (VIDE PESSOAS ABAIXO)
        	 vm.datePickerOpenStatus = {};
        	 vm.openCalendar = openCalendar;
        	 vm.datePickerOpenStatus.dataCadastro = false;
        	 vm.datePickerOpenStatus.dataIni = false;
             vm.datePickerOpenStatus.dataFinal = false;
             vm.datePickerOpenStatus.dataSituacao = false;
             
             //inicializa valores do auto
             vm.autoInfracao.valor = 0;        
             vm.autoInfracao.multa = 0;
             vm.autoInfracao.juros = 0;
             vm.autoInfracao.correcao = 0;
             
             
             //componente de relacionamentos
             vm.adicionar = adicionar;
            // vm.pessoas = Pessoa.query();
             $http.get('/api/pessoas/todas').then(function(response) {
                 vm.pessoas = response.data;
             });
             vm.pessoa;
             vm.juridicaPessoa;
             vm.pessoaRelacao = PessoaRelacao.query(); 
             //vm.temp2 = [];
             vm.temp2 = vm.autoInfracao.juridicaPessoa;
            
             
             
            if( vm.autoInfracao.juridicaPessoa == null){
             	vm.temp2 = [];
             }
    
            //fim componente de relacionamentos

        	 
        	  vm.items = items;
        	  vm.selected = {
        	    item: vm.items[0]
        	  };

        	  vm.ok = function () {
        		vm.autoInfracao.juridicaPessoa = vm.temp2;
        		vm.items.push(vm.autoInfracao);
        	    $uibModalInstance.close(vm.selected.item);
        	  };
        	  
        	  function openCalendar (date) {
                  vm.datePickerOpenStatus[date] = true;
              };

        	  vm.cancel = function () {
        	    $uibModalInstance.dismiss('cancel');
        	  };
          	 
        	  // componente relacionamentos
              function adicionar () {
              	
              	if(vm.pessoa != null ){
              		 
              		 var juridicaPessoaId =  {
             			      "pessoa": vm.pessoa
             			     
             			    }
              		 
              		 vm.juridicaPessoa =  {
               			      "pk": juridicaPessoaId,
               			      "pessoaRelacao": vm.pessoaJur.juridicaPessoa.pessoaRelacao.id,
               			      "createdBy": vm.pessoaJur.juridicaPessoa.pessoaRelacao.nome,
               			      
               			      "investigacao": '',
               			      "denunciado": false
               			      
             			 }
              		 
                   	vm.temp2.push(vm.juridicaPessoa);        		
              	}else{
                   	alert('Digite o nome ou CPF/CNPJ da Pessoa no campo indicado !');
                  }
                  
              }
              
            // fim de componente relacionamentos

       	};
        
       	
       	ModalInstanceAnexoCtrl.$inject = ['$uibModalInstance', 'itensAnexos', '$timeout', '$scope', '$stateParams', 'DataUtils', 'entity', 'Anexo', 'Documento', 'TipoAnexo'];
       	
        function ModalInstanceAnexoCtrl ( $uibModalInstance, itensAnexos, $timeout, $scope, $stateParams, DataUtils, entity , Anexo, Documento, TipoAnexo) {
      	  
        	var vm = this;
        	
        	vm.anexo = entity;

            vm.datePickerOpenStatus = {};
            vm.openCalendar = openCalendar;
            vm.datePickerOpenStatus.data = false; 
            
            vm.byteSize = DataUtils.byteSize;
            vm.openFile = DataUtils.openFile;

            vm.documentos = Documento.query();
            vm.tipoanexos = TipoAnexo.query();

            $timeout(function (){
                angular.element('.form-group:eq(1)>input').focus();
            });

            function clear () {
                $uibModalInstance.dismiss('cancel');
            }

            
            vm.datePickerOpenStatus.data = false;

            vm.setArquivo = function ($file, anexo) {
                if ($file) {
                    DataUtils.toBase64($file, function(base64Data) {
                        $scope.$apply(function() {
                            anexo.arquivo = base64Data;
                            anexo.arquivoContentType = $file.type;
                            anexo.nome = $file.name
                        });
                    });
                }
            };

            vm.itensAnexos = itensAnexos;
        	  vm.selectedAnexo = {
        	    itemAnexo: vm.itensAnexos[0]		
        	  };

        	  vm.okAnexo = function () {
        		vm.itensAnexos.push(vm.anexo);
        	    $uibModalInstance.close(vm.selectedAnexo.itemAnexo);
        	  };
        	  
        	  function openCalendar (date) {
                  vm.datePickerOpenStatus[date] = true;
              };
        	        	
        	  vm.cancelAnexo = function () {
        	    $uibModalInstance.dismiss('cancel');
        	  };
        	  
        };

       	
    DocumentoMySuffixDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', 'DataUtils', 'entity', '$uibModalInstance', '$q', 'Documento', 'Anexo', 'AutoInfracao', 'Notificacao', 'Operacao', 'Comarca', 'TipoDocumento', 'Orgao', 'ModalService', '$uibModal', 'PessoaJur'];

    function DocumentoMySuffixDialogController ($http, $timeout, $scope, $stateParams, DataUtils, entity, $uibModalInstance, $q, Documento, Anexo, AutoInfracao, Notificacao, Operacao, Comarca, TipoDocumento, Orgao, ModalService, $uibModal, PessoaJur) {
        var vm = this;

        vm.documento = entity;
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
       
        vm.anexos = Anexo.query();
        vm.autoinfracaos = AutoInfracao.query();
        vm.notificacaos = Notificacao.query();
        //vm.operacaos = Operacao.query();
        $http.get('/api/operacaos/todas').then( function(response) {
            vm.operacaos = response.data;
          });
        
//        vm.comarcas = Comarca.query({filter: 'documento-is-null'});
//        $q.all([vm.documento.$promise, vm.comarcas.$promise]).then(function() {
//            if (!vm.documento.comarca || !vm.documento.comarca.id) {
//                return $q.reject();
//            }
//            return Comarca.get({id : vm.documento.comarca.id}).$promise;
//        }).then(function(comarca) {
//            vm.comarcas.push(comarca);
//        });
//        vm.comarcas = Comarca.query();
        $http.get('/api/comarcas/todas').then( function(response) {
          vm.comarcas = response.data;
        });
        
        vm.tipodocumentos = TipoDocumento.query();
        
//        vm.orgaos = Orgao.query({filter: 'documento-is-null'});
//        $q.all([vm.documento.$promise, vm.orgaos.$promise]).then(function() {
//            if (!vm.documento.orgao || !vm.documento.orgao.id) {
//                return $q.reject();
//            }
//            return Orgao.get({id : vm.documento.orgao.id}).$promise;
//        }).then(function(orgao) {
//            vm.orgaos.push(orgao);
//        });
//        vm.orgaos = Orgao.query();
        $http.get('/api/orgaos/todos').then( function(response) {
          vm.orgaos = response.data;
        });
        
//        vm.pessoaJurs = PessoaJur.query();
//        vm.pessoaJurs = PessoaJur.query({filter: 'documento-is-null'});
//        $q.all([vm.documento.$promise, vm.pessoaJurs.$promise]).then(function() {
//            if (!vm.documento.pessoaJur || !vm.documento.pessoaJur.id) {
//                return $q.reject();
//            }
//            return PessoaJur.get({id : vm.documento.pessoaJur.id}).$promise;
//        }).then(function(pessoaJur) {
//            vm.pessoaJurs.push(pessoaJur);
//        });
//        vm.pessoaJurs = PessoaJur.query();
        $http.get('/api/pessoa-jurs/todas').then( function(response) {
          vm.pessoaJurs = response.data;
        });
        
        vm.items = [];
        vm.animationsEnabled = true;
        var modalInstance;
        vm.remover = remover;
        
        vm.itensAnexos = [];
        var modalInstanceAnexo;
        vm.removerItemAnexo = removerItemAnexo;   	
        
      /*  vm.gridOptions = {};
        
        vm.reset = reset;
        
        function reset() {
          vm.gridOptions.data = [];
          vm.gridOptions.columnDefs = [];
        }
        */
        
        if( vm.documento.autos != null){
        	vm.items = vm.documento.autos;
        }
        
        if( vm.documento.anexos != null){
       	 	vm.itensAnexos = vm.documento.anexos;
        }

        
       

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
        	$uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
           
            
            vm.documento.autos = vm.items;
            vm.documento.anexos = vm.itensAnexos;
         
            
            if (vm.documento.id !== null) {
                Documento.update(vm.documento, onSaveSuccess, onSaveError);
            } else {
            	
                Documento.save(vm.documento, onSaveSuccess, onSaveError);
                
                
                
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:documentoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.dataDenuncia = false;
        vm.datePickerOpenStatus.dataArquivamento = false;
        vm.datePickerOpenStatus.dataCadastro = false;
        vm.datePickerOpenStatus.dataMudLoc = false;
        vm.datePickerOpenStatus.dataOficioEntrada = false;
        vm.datePickerOpenStatus.dataOficioSaida = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
        
      
        	
        vm.open = function (size, parentSelector) {
            var parentElem = parentSelector ? 
              angular.element($document[0].querySelector('.panel panel-primary ' + parentSelector)) : undefined;
            var modalInstance = $uibModal.open({
              animation: vm.animationsEnabled,
              ariaLabelledBy: 'modal-title',
              ariaDescribedBy: 'modal-body',
              templateUrl: 'myModalContent.html',
              controller: 'ModalInstanceCtrl',
              controllerAs: 'vm',
              size: 'lg',
              appendTo: parentElem,
              resolve: {
                items: function () {
                  return vm.items;
                },
             entity: function () {
                return {
                    numero: null,
                    dataCadastro: null,
                    dataIni: null,
                    dataFinal: null,
                    valor: null,
                    multa: null,
                    juros: null,
                    correcao: null,
                    dataSituacao: null,
                    id: null
                };
            }
              }
            });

            modalInstance.result.then(function (selectedItem) {
              vm.selected = selectedItem;
            }, function () {
             // $log.info('Modal dismissed at: ' + new Date());
            });
          };
              
              
           function remover (id) {
        	
        	var ref = id;
            
        	vm.items.forEach(function(element, index, array){
                if(element.numero == ref.numero ){
                	vm.items.splice(index, 1);
                }
              });
        	
        }
        
        vm.openAnexo = function (size, parentSelector) {
            var parentElem = parentSelector ? 
              angular.element($document[0].querySelector('.panel panel-primary ' + parentSelector)) : undefined;
            var modalInstanceAnexo = $uibModal.open({
              animation: vm.animationsEnabled,
              ariaLabelledBy: 'modal-title',
              ariaDescribedBy: 'modal-body',
              templateUrl: 'myModalAnexoContent.html', 			
              controller: 'ModalInstanceAnexoCtrl',
              controllerAs: 'vm',
              size: 'lg',
              appendTo: parentElem,
              resolve: {
                itensAnexos: function () {
                  return vm.itensAnexos;
                },
                entity: function () {
                return {
                    data: null,
                    arquivo: null,
                    arquivoContentType: null,
                    id: null,
                    nome: null
                    
                };
            }
              }
            });
            
            modalInstanceAnexo.result.then(function (selectedItem) {			
                vm.selectedAnexo = selectedItem;
              }, function () {
               // $log.info('Modal dismissed at: ' + new Date());
              });
            };
            
            
            function removerItemAnexo (arquivo) {						
            	
            	var ref = arquivo;
            
            	vm.itensAnexos.forEach(function(element, index, array){
                    if(element.arquivo == ref.arquivo ){
                    	vm.itensAnexos.splice(index, 1);
                    }
                  });
            	
            }

        
        
       
    }
    
/*    crt.directive("fileread", [function () {
  	  return {
  		    scope: {
  		      opts: '='
  		    },
  		    link: function ($scope, $elm, $attrs) {
  		      $elm.on('change', function (changeEvent) {
  		        var reader = new FileReader();
  		        
  		        reader.onload = function (evt) {
  		          $scope.$apply(function () {
  		            var data = evt.target.result;
  		            
  		            var workbook = XLSX.read(data, {type: 'binary'});
  		            
  		            var headerNames = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]], { header: 1 })[0];
  		            
  		            var data = XLSX.utils.sheet_to_json( workbook.Sheets[workbook.SheetNames[0]]);
  		            
  		            $scope.opts.columnDefs = [];
  		            headerNames.forEach(function (h) {
  		              $scope.opts.columnDefs.push({ field: h });
  		            });
  		            
  		            $scope.opts.data = data;
  		            
  		            $elm.val(null);
  		          });
  		        };
  		        
  		        reader.readAsBinaryString(changeEvent.target.files[0]);
  		      });
  		    }
  		  }
  }]);*/
    
    
})();
