(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('NotificacaoMySuffixDeleteController',NotificacaoMySuffixDeleteController);

    NotificacaoMySuffixDeleteController.$inject = ['$uibModalInstance', 'entity', 'Notificacao'];

    function NotificacaoMySuffixDeleteController($uibModalInstance, entity, Notificacao) {
        var vm = this;

        vm.notificacao = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Notificacao.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
