(function() {
    'use strict';

    angular
        .module('siacapApp')
        .factory('SaldoSearch', SaldoSearch);

    SaldoSearch.$inject = ['$resource'];

    function SaldoSearch($resource) {
        var resourceUrl =  'api/_search/saldos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
