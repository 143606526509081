(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('SegmentoMySuffixDetailController', SegmentoMySuffixDetailController);

    SegmentoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Segmento'];

    function SegmentoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Segmento) {
        var vm = this;

        vm.segmento = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:segmentoUpdate', function(event, result) {
            vm.segmento = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
