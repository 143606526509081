(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('SituacaoMySuffixDetailController', SituacaoMySuffixDetailController);

    SituacaoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Situacao', 'Saldo'];

    function SituacaoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Situacao, Saldo) {
        var vm = this;

        vm.situacao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:situacaoUpdate', function(event, result) {
            vm.situacao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
