(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('NotificacaoMySuffixDialogController', NotificacaoMySuffixDialogController);

    NotificacaoMySuffixDialogController.$inject = ['$http','$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'Notificacao', 'Documento', 'Pessoa', 'AutoInfracao'];

    function NotificacaoMySuffixDialogController ($http,$timeout, $scope, $stateParams, $uibModalInstance, entity, Notificacao, Documento, Pessoa, AutoInfracao) {
        var vm = this;

        vm.notificacao = entity;
        vm.notificacao.ano = new Date().getFullYear();
        vm.clear = clear;
        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;
        vm.save = save;
        //vm.documentos = Documento.query();
        $http.get('/api/documentos/todos').then(function(response) {
        	vm.documentos = response.data;
        });
        //vm.pessoas = Pessoa.query();
        $http.get('/api/pessoas/todas').then(function(response) {
            vm.pessoas = response.data;
        });
        var auto_id ;
        var autoInfracao;
        
        
        if (vm.notificacao.id == null) {
        	Documento.get({id: vm.notificacao.documento},function(doc){
        		//on success callback function
        		vm.notificacao.documento = doc;
        		auto_id = vm.notificacao.documento.autos[0].id;
            	AutoInfracao.get({id: auto_id},function(auto){
            		//on success callback function
            		autoInfracao = auto;
            	});
        	});
        	
        	
        	
        }
        

    
    
        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.notificacao.id !== null) {
                Notificacao.update(vm.notificacao, onSaveSuccess, onSaveError);
            } else {
            	if(typeof autoInfracao == "undefined"){
            		alert("Não é possível gerar notificação sem auto de infração cadastrado");
            		
            	
            	} else if(isNullOrUndef(autoInfracao.juridicaPessoa[0])) {
            		alert("O Auto de Infração deve ter pelo menos 1 pessoa relacionada!");
            	} else {
            		gerarnotificacao();
            	
            	}
               
                
            }
        }
        
        function loadFile(url,callback){
            JSZipUtils.getBinaryContent(url,callback);
        }
        
        function gerarnotificacao(){
        	
        	
        	
        	//alert(autoInfracao);
        	if (vm.notificacao.documento.tipo != null) {
	        	if (vm.notificacao.documento.tipo.id == 1) {
	        		try {
	        		generateDocx2("content/images/examples/cofimp.docx");
	        		var now = new Date;
	        		vm.notificacao.data = now;
	        		Notificacao.save(vm.notificacao, onSaveSuccess, onSaveError);
	        		}catch (error){
	        			alert("problema");
	        		}
	        		
				}else if(vm.notificacao.documento.tipo.id == 11) {
					
					generateDocx2("content/images/examples/iss.docx");
					var now = new Date;
					vm.notificacao.data = now;
	        		Notificacao.save(vm.notificacao, onSaveSuccess, onSaveError);
					
					
				}else if(vm.notificacao.documento.tipo.id == 2){
					alert("sem documento");
				
				}
	        	
	        	
	        	
	        }else {
				alert("Documento sem tipo, favor ajustar um tipo para o sistema gerar uma notificação");
				clear();
			}
        	
        }
        
       function isNullOrUndef(obj) {
        	  return obj == null;
       }  
        
        function generateDocx2(file) {
        	            
        		loadFile(file,function(error,content){
                if (error) { throw error };
                var zip = new JSZip(content);
                var dataAtual = new Date();
                var monName = new Array ("janeiro", "fevereiro", "março", "abril", "maio", "junho", "agosto", "outubro", "novembro", "dezembro");
                var stringDate = dataAtual.getDate() + ' de ' + monName[dataAtual.getMonth()] + ' de ' + dataAtual.getFullYear();
                var doc=new Docxtemplater().loadZip(zip)
                
                
                	doc.setData({
                        notificado: ( (typeof autoInfracao.juridicaPessoa[0] != "undefined") ? autoInfracao.juridicaPessoa[0].pk.pessoa.nome : "***") ,
                        tombo:   ( !isNullOrUndef(vm.notificacao.documento.id) ? vm.notificacao.documento.id : "***") , 
                        arquimedes:   ( !isNullOrUndef(vm.notificacao.documento.arquimedes) ? vm.notificacao.documento.arquimedes : "***") , 
                        phone: '',
                        description: 'Notificação',
                        numero:   ( !isNullOrUndef(vm.notificacao.numero) ? vm.notificacao.numero : "***") , 
                        ano:  ( !isNullOrUndef(vm.notificacao.ano) ? vm.notificacao.ano : "***") , 
//                        promotor: ( !isNullOrUndef(vm.notificacao.promotor) ? vm.notificacao.promotor : "***") , 
//                        cargo: ( !isNullOrUndef(vm.notificacao.cargo) ? vm.notificacao.cargo : "***") , 
//                        remetente: ( !isNullOrUndef(vm.notificacao.documento.remetente) ? vm.notificacao.documento.remetente.nome : "***") ,
                        date: stringDate,
                        endereco_comercial: ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? (vm.notificacao.documento.pessoaJur.logradouro)+', ' : "* ")
                        + ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? (vm.notificacao.documento.pessoaJur.numero)+', ' : "* ") 
                        + ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? (vm.notificacao.documento.pessoaJur.bairro)+', ' : "* ")  
                        + ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? (vm.notificacao.documento.pessoaJur.cidade)+'/' : "* ")
                        + ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? (vm.notificacao.documento.pessoaJur.uf)+', ' : "* ")
                        + ', CEP: ' + ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? vm.notificacao.documento.pessoaJur.cep : "* "),
                        endereco_residencial: ( ((typeof autoInfracao.juridicaPessoa[0] != "undefined") || autoInfracao.juridicaPessoa.length > 0 ) ? autoInfracao.juridicaPessoa[0].pk.pessoa.logradouro : "***")  + ', ' + ( !isNullOrUndef(autoInfracao.juridicaPessoa[0].pk.pessoa) ? autoInfracao.juridicaPessoa[0].pk.pessoa.numero : "***") 
                        + ', ' + ( (typeof autoInfracao.juridicaPessoa[0] != "undefined") ? autoInfracao.juridicaPessoa[0].pk.pessoa.bairro : "***") +  
                        + ( !isNullOrUndef(autoInfracao.juridicaPessoa[0].pk.pessoa) ? autoInfracao.juridicaPessoa[0].pk.pessoa.cidade : "***") /* + '/' +( !isNullOrUndef(autoInfracao.juridicaPessoa[0].pk.pessoa) ? autoInfracao.juridicaPessoa[0].pk.pessoa.uf : "***") */ + ', CEP: ' 
                        + ( !isNullOrUndef(autoInfracao.juridicaPessoa[0].pk.pessoa) ? autoInfracao.juridicaPessoa[0].pk.pessoa.cep : "***") + '.',
                        empresa: ( !isNullOrUndef(vm.notificacao.documento.pessoaJur) ? vm.notificacao.documento.pessoaJur.nome : "***") ,
                        auto: ( !isNullOrUndef(vm.notificacao.documento.autos[0]) ? vm.notificacao.documento.autos[0].numero : "***") 
                    });
                                

                try {
                    // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                    doc.render()
                }
                catch (error) {
                    var e = {
                        message: error.message,
                        name: error.name,
                        stack: error.stack,
                        properties: error.properties,
                    }
                    console.log(JSON.stringify({error: e}));
                    // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
                    throw error;
                }

                var out=doc.getZip().generate({
                    type:"blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }) //Output the document using Data-URI
                saveAs(out,"COMUNICAÇÃO - " + vm.notificacao.numero + "/" + vm.notificacao.ano +".docx")
            });        	
       
        	
        	
        }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:notificacaoUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        vm.datePickerOpenStatus.data = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
