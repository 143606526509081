(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('ComarcaMySuffixDeleteController',ComarcaMySuffixDeleteController);

    ComarcaMySuffixDeleteController.$inject = ['$uibModalInstance', 'entity', 'Comarca'];

    function ComarcaMySuffixDeleteController($uibModalInstance, entity, Comarca) {
        var vm = this;

        vm.comarca = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Comarca.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
