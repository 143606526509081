(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('PessoaFisicaMySuffixDeleteController',PessoaFisicaMySuffixDeleteController);

    PessoaFisicaMySuffixDeleteController.$inject = ['$uibModalInstance', 'entity', 'PessoaFisica'];

    function PessoaFisicaMySuffixDeleteController($uibModalInstance, entity, PessoaFisica) {
        var vm = this;

        vm.pessoaFisica = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            PessoaFisica.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
