(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('OrgaoMySuffixDetailController', OrgaoMySuffixDetailController);

    OrgaoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Orgao'];

    function OrgaoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Orgao) {
        var vm = this;

        vm.orgao = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:orgaoUpdate', function(event, result) {
            vm.orgao = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
