(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('OficioMySuffixDetailController', OficioMySuffixDetailController);

    OficioMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Oficio', 'Orgao', 'Documento'];

    function OficioMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Oficio, Orgao, Documento) {
        var vm = this;

        vm.oficio = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:oficioUpdate', function(event, result) {
            vm.oficio = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
