(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('NotificacaoMySuffixController', NotificacaoMySuffixController);

    NotificacaoMySuffixController.$inject = ['$state', '$scope',  'Notificacao', 'NotificacaoSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function NotificacaoMySuffixController($state, $scope, Notificacao, NotificacaoSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.etiqueta = etiqueta;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.modelo = [];
        
        $scope.toggleSelection = function toggleSelection(gender) {
            var idx = vm.modelo.indexOf(gender);
            if (idx > -1) {
              // is currently selected
            	vm.modelo.splice(idx, 1);
             }
             else {
               // is newly selected
            	 vm.modelo.push(gender);
             }
          };
          

          
          $scope.toggleAll = function toggleAll(gender) {
            for (var element in  gender) {
            	$scope.toggleSelection(element);
			}
          };

      
        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                NotificacaoSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Notificacao.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.notificacaos = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        
        function geraretiqueta(){
        	
        	
        
	        		generateDocx2("content/images/examples/etiqueta.docx");
	        		
	        	
        	
        }
        
        
        function isNull(obj) {
      	  return obj != null;
        }  
        
        function isNotUndefined(obj) {
        	return obj != undefined;
        }
        
        function generateDocx2(file) {
            
    		loadFile(file,function(error,content){
            if (error) { throw error };
            var zip = new JSZip(content);
            
            var etiquetas = [];            
            
            
            for (var element in vm.modelo) {
            	var resultado = vm.modelo[element];
            	
            	etiquetas.push(
            			{
                            "not": resultado.id ,
                            "ano":   resultado.ano , 
                            "empresa":   resultado.documento.pessoaJur.nome , 
                            "t": (isNotUndefined(resultado.documento.arquimedes) ? resultado.documento.arquimedes : ""),
                            "endereco": ( isNull(resultado.documento.pessoaJur.logradouro) ? resultado.documento.pessoaJur.logradouro : "***")  + ', ' + ( isNull(resultado.documento.pessoaJur.numero) ? resultado.documento.pessoaJur.numero : "***") 
                            + ', ' + ( isNull(resultado.documento.pessoaJur.bairro) ? resultado.documento.pessoaJur.bairro : "***") + ' - ' 
                            + ( isNull(resultado.documento.pessoaJur.cidade) ? resultado.documento.pessoaJur.cidade : "***") /* + '/' +( isNull(vm.notificacao.documento.pessoaJur) ? vm.notificacao.documento.pessoaJur.uf : "***") */ + ', CEP: ' 
                            + ( isNull(resultado.documento.pessoaJur.cep) ? resultado.documento.pessoaJur.cep : "***") + '.'
            			}
            	
            	);
			}
            	
           
            var doc=new Docxtemplater().loadZip(zip)
            doc.setData({etiquetas: etiquetas});
            

            try {
                // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
                doc.render()
            }
            catch (error) {
                var e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties,
                }
                console.log(JSON.stringify({error: e}));
                // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
                throw error;
            }

            var out=doc.getZip().generate({
                type:"blob",
                mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            }) //Output the document using Data-URI
            saveAs(out,"Etiqueta" +".docx")
        });        	
   
    	
    	
    }
        
        function loadFile(url,callback){
            JSZipUtils.getBinaryContent(url,callback);
        }
        
        
        function etiqueta() {
        	
        	if (vm.modelo.length == 0) {
        		alert("Selecione alguma notificação para gerar uma etiqueta.");
        		
        	}else {
        		if (vm.modelo.length < 20) {
        			alert("Você pode imprimir 20 Etiquetas para 2 páginas. Deseja continuar mesmo assim ? Com apenas " + vm.modelo.length + " Etiquetas ?");
        		}
        		
        		
        		
        		geraretiqueta();
        		
        		
        		
        		
        	
        	}
        	
        	
        	
        	
        	}
        	
        	
        	
        	
        
    }
})();
