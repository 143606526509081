(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('OficioMySuffixController', OficioMySuffixController);

    OficioMySuffixController.$inject = ['$state', '$scope', 'Oficio', 'OficioSearch', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams'];

    function OficioMySuffixController($state, $scope, Oficio, OficioSearch, ParseLinks, AlertService, paginationConstants, pagingParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.clear = clear;
        vm.search = search;
        vm.loadAll = loadAll;
        vm.etiqueta = etiqueta;
        vm.searchQuery = pagingParams.search;
        vm.currentSearch = pagingParams.search;
        vm.modelo = [];
        
        $scope.toggleSelection = function toggleSelection(gender) {
        	var idx = vm.modelo.indexOf(gender);
        	if (idx > -1) {
        		// is currently selected
        		vm.modelo.splice(idx, 1);
        	}
        	else {
        		// is newly selected
        		vm.modelo.push(gender);
        	}
        };

  
        $scope.toggleAll = function toggleAll(gender) {
        	for (var element in  gender) {
        		$scope.toggleSelection(element);
        	}
        };

        loadAll();

        function loadAll () {
            if (pagingParams.search) {
                OficioSearch.query({
                    query: pagingParams.search,
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            } else {
                Oficio.query({
                    page: pagingParams.page - 1,
                    size: vm.itemsPerPage,
                    sort: sort()
                }, onSuccess, onError);
            }
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.oficios = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

        function search(searchQuery) {
            if (!searchQuery){
                return vm.clear();
            }
            vm.links = null;
            vm.page = 1;
            vm.predicate = '_score';
            vm.reverse = false;
            vm.currentSearch = searchQuery;
            vm.transition();
        }

        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = true;
            vm.currentSearch = null;
            vm.transition();
        }
        
        function geraretiqueta(){
        	generateDocx2("content/images/examples/etiqueta-de-oficio.docx");
        }

        function isNull(obj) {
        	return obj == null;
        }
        
//        function isUndefined(obj) {
//        	return obj === undefined;
//        }

        function generateDocx2(file) {
    
        	loadFile(file,function(error,content){
        		if (error) { throw error };
        		var zip = new JSZip(content);
    
        		var etiquetas = [];
    
        		for (var element in vm.modelo) {
        			var resultado = vm.modelo[element];
        			        			
        		
        					
        				etiquetas.push({
            					
                                //"oficio": resultado.id,
                                //"ano":   resultado.ano ,
            					"tratamento": "Excelentíssimo(a) Senhor(a)",
                                "pj":   resultado.promotor, 
                                "destino": resultado.executor.nome,
                                "endereco": ( !isNull(resultado.executor.logradouro) ? (resultado.executor.logradouro)+', ' : "* ")
                                + ( !isNull(resultado.executor.numero) ? (resultado.executor.numero+', ') : "") 
                                + ( !isNull(resultado.executor.bairro) ? (resultado.executor.bairro+', ') : "* ") 
                                + ( !isNull(resultado.executor.cidade) ? (resultado.executor.cidade)+'/' : "* ")
                                + ( !isNull(resultado.executor.uf) ? (resultado.executor.uf)+', ' : "* ")
                                + 'CEP: ' + ( !isNull(resultado.executor.cep) ? (resultado.executor.cep) : "* ")                    
        				});

        			
        		}
    	
			    var doc=new Docxtemplater().loadZip(zip)
			    doc.setData({etiquetas: etiquetas});
    

			    try {
			        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
			        doc.render()
			    }
			    catch (error) {
			        var e = {
			            message: error.message,
			            name: error.name,
			            stack: error.stack,
			            properties: error.properties,
			        }
			        console.log(JSON.stringify({error: e}));
			        // The error thrown here contains additional information when logged with JSON.stringify (it contains a property object).
			        throw error;
			    }
			
			    var out=doc.getZip().generate({
			    	type:"blob",
			    	mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
			    }) //Output the document using Data-URI
			    saveAs(out,"Etiqueta" +".docx")
			    });        	
			
        }

		function loadFile(url,callback){
		    JSZipUtils.getBinaryContent(url,callback);
		}


		function etiqueta() {
			
			if (vm.modelo.length == 0) {
				alert("Selecione algum ofício para gerar uma etiqueta.");
				
			}else {
				if (vm.modelo.length < 20) {
					alert("Você pode imprimir até 20 etiquetas para 2 páginas. Deseja continuar mesmo assim?");
				}
				
				geraretiqueta();
			
			}
			
		}
		        
	}
})();
