(function() {
    'use strict';

    angular
        .module('siacapApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('importacao-socio', {
            parent: 'entity',
            url: '/importacao-socio?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'siacapApp.importacaoSocio.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/importacao-socio/importacao-socios.html',
                    controller: 'ImportacaoSocioController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('importacaoSocio');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('importacao-socio-detail', {
            parent: 'importacao-socio',
            url: '/importacao-socio/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'siacapApp.importacaoSocio.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/importacao-socio/importacao-socio-detail.html',
                    controller: 'ImportacaoSocioDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('importacaoSocio');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ImportacaoSocio', function($stateParams, ImportacaoSocio) {
                    return ImportacaoSocio.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'importacao-socio',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('importacao-socio-detail.edit', {
            parent: 'importacao-socio-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/importacao-socio/importacao-socio-dialog.html',
                    controller: 'ImportacaoSocioDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ImportacaoSocio', function(ImportacaoSocio) {
                            return ImportacaoSocio.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('importacao-socio.new', {
            parent: 'importacao-socio',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/importacao-socio/importacao-socio-dialog.html',
                    controller: 'ImportacaoSocioDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                arquivo: null,
                                arquivoContentType: null,
                                data: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('importacao-socio', null, { reload: 'importacao-socio' });
                }, function() {
                    $state.go('importacao-socio');
                });
            }]
        })
        .state('importacao-socio.edit', {
            parent: 'importacao-socio',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/importacao-socio/importacao-socio-dialog.html',
                    controller: 'ImportacaoSocioDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['ImportacaoSocio', function(ImportacaoSocio) {
                            return ImportacaoSocio.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('importacao-socio', null, { reload: 'importacao-socio' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('importacao-socio.delete', {
            parent: 'importacao-socio',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/importacao-socio/importacao-socio-delete-dialog.html',
                    controller: 'ImportacaoSocioDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['ImportacaoSocio', function(ImportacaoSocio) {
                            return ImportacaoSocio.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('importacao-socio', null, { reload: 'importacao-socio' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
