(function() {
    'use strict';

    angular
        .module('siacapApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('documento-my-suffix', {
            parent: 'entity',
            url: '/documento-my-suffix?page&sort&search',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'siacapApp.documento.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/documento/documentosmySuffix.html',
                    controller: 'DocumentoMySuffixController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                search: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documento');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('documento-my-suffix-detail', {
            parent: 'documento-my-suffix',
            url: '/documento-my-suffix/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'siacapApp.documento.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/documento/documento-my-suffix-detail.html',
                    controller: 'DocumentoMySuffixDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('documento');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Documento', function($stateParams, Documento) {
                    return Documento.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'documento-my-suffix',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('documento-my-suffix-detail.edit', {
            parent: 'documento-my-suffix-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/documento/documento-my-suffix-dialog.html',
                    controller: 'DocumentoMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Documento', function(Documento) {
                            return Documento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documento-my-suffix.new', {
            parent: 'documento-my-suffix',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/documento/documento-my-suffix-dialog.html',
                    controller: 'DocumentoMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                numeroOrigem: null,
                                arquimedes: null,
                                observacao: null,
                                npu: null,
                                dataDenuncia: null,
                                dataArquivamento: null,
                                dataCadastro: null,
                                dataOficioEntrada: null,
                                numeroOficioEntrada: null,
                                dataOficioSaida: null,
                                numeroOficioSaida: null,
                                usuario: null,
                                dataMudLoc: null,
                                destinatarioLoc: null,
                                autos: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                    $state.go('documento-my-suffix');
                });
            }]
        })
        .state('documento-my-suffix.edit', {
            parent: 'documento-my-suffix',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/documento/documento-my-suffix-dialog.html',
                    controller: 'DocumentoMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Documento', function(Documento) {
                            return Documento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documento-my-suffix.delete', {
            parent: 'documento-my-suffix',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/documento/documento-my-suffix-delete-dialog.html',
                    controller: 'DocumentoMySuffixDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Documento', function(Documento) {
                            return Documento.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('documento-my-suffix.notif', {
            parent: 'notificacao-my-suffix',
            url: '/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/notificacao/notificacao-my-suffix-dialog.html',
                    controller: 'NotificacaoMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                        entity: function () {
                            return {
                                numero: null,
                                ano: null,
                                data: null,
                                cargo: null,
                                promotor: null,
                                documento:  $stateParams.id,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                    $state.go('documento-my-suffix');
                });
            }]
        })
        .state('documento-my-suffix.ofic', {
            parent: 'oficio-my-suffix',
            url: '/{id}',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/oficio/oficio-my-suffix-dialog.html',
                    controller: 'OficioMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                    	
                        entity: function () {
                            return {
                                data: null,
                                observacao: null,
                                documento:  $stateParams.id,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                    $state.go('documento-my-suffix');
                });
            }]
        })
        .state('documento-my-suffix.auto', {
            parent: 'documento-my-suffix.new',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/documento/auto-infracao-doc-dialog.html',
                    controller: 'DocumentoMySuffixDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                numero: null,
                                dataIni: null,
                                dataFinal: null,
                                valor: null,
                                multa: null,
                                juros: null,
                                correcao: null,
                                dataSituacao: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('documento-my-suffix', null, { reload: 'documento-my-suffix' });
                }, function() {
                	$state.go('^', {}, { reload: false });
                });
            }]
        });  
    }

})();
