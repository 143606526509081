(function() {
    'use strict';

    angular
        .module('siacapApp')
        .factory('ComarcaSearch', ComarcaSearch);

    ComarcaSearch.$inject = ['$resource'];

    function ComarcaSearch($resource) {
        var resourceUrl =  'api/_search/comarcas/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
