(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('PessoaJurMySuffixDialogController', PessoaJurMySuffixDialogController);

    PessoaJurMySuffixDialogController.$inject = ['$http', '$timeout', '$scope', '$stateParams', '$uibModalInstance', 'entity', 'PessoaJur', 'Cnae', 'Segmento', 'Atividade', 'Pessoa', 'PessoaRelacao'];

    function PessoaJurMySuffixDialogController ($http, $timeout, $scope, $stateParams, $uibModalInstance, entity, PessoaJur, Cnae, Segmento, Atividade, Pessoa, PessoaRelacao) {
        var vm = this;

        vm.pessoaJur = entity;
        vm.clear = clear;
        vm.adicionar = adicionar;
//        vm.remover = remover;
        vm.save = save;
        vm.ufs = ['AC','AL','AM','AP','BA','CE','DF','ES','GO','MA','MG','MS','MT','PA','PB','PE','PI','PR','RJ','RN','RO','RR','RS','SC','SE','SP','TO'];
        //vm.cnaes = Cnae.query();
        $http.get('/api/cnaes/todos').then(function(response) {
            vm.cnaes = response.data;
        });
        
        //vm.segmentos = Segmento.query();
        $http.get('/api/segmentos/todos').then(function(response) {
            vm.segmentos = response.data;
        });
        
        //vm.atividades = Atividade.query();
        $http.get('/api/atividades/todas').then(function(response) {
            vm.atividades = response.data;
        });

        
        vm.pessoas = Pessoa.query();
        vm.pessoa;
        vm.juridicaPessoa;
        vm.relacionados = vm.pessoaJur.temp;		
        vm.pessoaRelacao = PessoaRelacao.query();        
        
        vm.temp2 = vm.pessoaJur.juridicaPessoa;
        vm.selectedUF = 'PE'; // para máscara de Inscrição Estadual
    
        var relacionamentos  = vm.pessoaRelacao.nome;
        
        
        if( vm.pessoaJur.temp == null){
        	vm.temp2 = [];
        }

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            
                       
            vm.pessoaJur.juridicaPessoa = vm.temp2;
            
            if (vm.pessoaJur.id !== null) {
            	
            	PessoaJur.update(vm.pessoaJur, onSaveSuccess, onSaveError);
            
            } else {
            	
                PessoaJur.save(vm.pessoaJur, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('siacapApp:pessoaJurUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }
        
        
        
        
        
        function adicionar () {
        	
        	if(vm.pessoa != null ){
        		 
        		 var juridicaPessoaId =  {
       			      "pessoa": vm.pessoa
       			     
       			    }
        		 
        		 vm.juridicaPessoa =  {
         			      "pk": juridicaPessoaId,
         			      "pessoaRelacao": vm.pessoaJur.juridicaPessoa.pessoaRelacao.id,
         			      "createdBy": vm.pessoaJur.juridicaPessoa.pessoaRelacao.nome,
         			      
         			      "investigacao": '',
         			      "denunciado": false
         			      
       			 }
        		 
             	vm.temp2.push(vm.juridicaPessoa);        		
        	}else{
             	alert('Digite o nome ou CPF/CNPJ da Pessoa no campo indicado !');
            }
            
        }
        

//        function remover (id) {
//        	
//        	var ref = id;
//        	
//        	vm.temp2.forEach(function(element, index, array){
//                if(element.pk.pessoa.nome == ref.nome ){
//                	vm.temp2.splice(index, 1);
//                
//                }
//              });
//        	
//        }
        
       

    }
})();
