(function() {
    'use strict';
    angular
        .module('siacapApp')
        .factory('AutoInfracao', AutoInfracao);

    AutoInfracao.$inject = ['$resource', 'DateUtils'];

    function AutoInfracao ($resource, DateUtils) {
        var resourceUrl =  'api/auto-infracaos/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.dataIni = DateUtils.convertDateTimeFromServer(data.dataIni);
                        data.dataFinal = DateUtils.convertDateTimeFromServer(data.dataFinal);
                        data.dataSituacao = DateUtils.convertDateTimeFromServer(data.dataSituacao);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
