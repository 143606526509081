(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('CnaeMySuffixDeleteController',CnaeMySuffixDeleteController);

    CnaeMySuffixDeleteController.$inject = ['$uibModalInstance', 'entity', 'Cnae'];

    function CnaeMySuffixDeleteController($uibModalInstance, entity, Cnae) {
        var vm = this;

        vm.cnae = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (id) {
            Cnae.delete({id: id},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
