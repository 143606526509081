(function() {
    'use strict';

    angular
        .module('siacapApp')
        .controller('DocumentoMySuffixDetailController', DocumentoMySuffixDetailController);

    DocumentoMySuffixDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'Documento', 'Anexo', 'AutoInfracao', 'Notificacao', 'Operacao', 'Comarca', 'TipoDocumento', 'Orgao'];

    function DocumentoMySuffixDetailController($scope, $rootScope, $stateParams, previousState, entity, Documento, Anexo, AutoInfracao, Notificacao, Operacao, Comarca, TipoDocumento, Orgao) {
        var vm = this;

        vm.documento = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('siacapApp:documentoUpdate', function(event, result) {
            vm.documento = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
